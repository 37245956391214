import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HistoryIcon from '@mui/icons-material/History';
import ViewListIcon from '@mui/icons-material/ViewList';
import { ListItemButton } from '@mui/material';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

export default function MiniDrawer(props) {
  const classes = useStyles();

  window.addEventListener('resize', () => {
    if (window.innerWidth < 700 && props.navBarOpen) {
      props.handleNavBarClose(false);
    }
  });

  return (
    <Drawer
      variant={'temporary'}
      open={props.navBarOpen}
      onClose={props.handleNavBarClose}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: props.navBarOpen,
        [classes.drawerClose]: !props.navBarOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: props.navBarOpen,
          [classes.drawerClose]: !props.navBarOpen,
        }),
      }}
    >
      <List>
        <div>
          <ListItemButton component={Link} to="/dashboard">
            <ListItemIcon>
              <DashboardIcon/>
            </ListItemIcon>
            <ListItemText primary="Dashboard"/>
          </ListItemButton>
          <ListItemButton component={Link} to="/history">
            <ListItemIcon>
              <HistoryIcon/>
            </ListItemIcon>
            <ListItemText primary="History"/>
          </ListItemButton>
          <ListItemButton component={Link} to="/blacklist">
            <ListItemIcon>
              <ViewListIcon/>
            </ListItemIcon>
            <ListItemText primary="Blacklist"/>
          </ListItemButton>
        </div>
      </List>
    </Drawer>
  );
}
