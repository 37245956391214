import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'html-react-parser';

const historyTableRowStyles = theme => ({
    summaryPanel: {
      height: 60,
    },
    summaryTable: {
      tableLayout: 'fixed',
    },
    summaryCell: {
      align: 'left',
      paddingLeft: theme.spacing(0),
      borderColor: 'white',
      border: '0 !important',
    },
    summaryTypography: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    container: {
      paddingLeft: theme.spacing(0),
    },
    list: {
      width: '100%',
    },
    listItem: {
      paddingLeft: theme.spacing(0),
      whiteSpace: 'break-spaces',
      overflowWrap: 'anywhere',
    },
    headerTypographyStyle: {
      display: 'inline-block',
    }
})

class HistoryTableRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };

    this.handleExpandedChange = this.handleExpandedChange.bind(this);
  }

  handleExpandedChange = () => {
    this.setState(prevState => ({
      expanded: !prevState.expanded,
    }));
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Auto-collapse the panel when the item has changed
    if (prevProps.item !== this.props.item && this.state.expanded) {
      this.setState({
        expanded: false,
      });
    }
  }

  render() {
    const { item, dataID, cellConfig, classes } = this.props;
    const status = (item.error == null) ? 'OK' : 'ERROR';

    return (
      <TableRow key={'mainRow' + dataID}>
        <TableCell key={'mainCell' + dataID} padding={'none'} colSpan={9}>
          <Accordion square={true} expanded={this.state.expanded} onChange={this.handleExpandedChange}>
            <AccordionSummary className={classes.summaryPanel} expandIcon={<ExpandMoreIcon/>}>
              <Table className={classes.summaryTable}>
                <TableBody>
                  <TableRow key={'summaryRow' + dataID}>
                    {Object.keys(item).map((key, index) => (
                      <TableCell key={key + dataID}
                                 className={classes.summaryCell}
                                 style={{ width: cellConfig[index].width }}>
                        <Typography className={classes.summaryTypography} variant={'body2'}>
                          {
                            (key === 'error') ? status :
                            (key === 'attachments' && item[key] ? ReactHtmlParser(item[key]) :
                              item[key])
                          }
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </AccordionSummary>
            <AccordionDetails>
              <List className={classes.list}>
                {Object.keys(item).map((key, index) => (
                  <Container key={key + dataID} className={classes.container}>
                    <ListItem key={key + dataID} className={classes.listItem}>
                      <Typography component="span" variant={'body2'}>
                        <Typography component="span" className={classes.headerTypographyStyle} variant={'subtitle2'}>
                          {cellConfig[index].desc}:&nbsp;
                        </Typography>
                        {
                          (key === 'error') ? status :
                            (item[key] ? ReactHtmlParser(item[key]) : item[key])
                        }
                      </Typography>
                    </ListItem>
                    <Divider/>
                  </Container>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </TableCell>
      </TableRow>
    );
  }
}

HistoryTableRow.propTypes = {
  item: PropTypes.object.isRequired,
  dataID: PropTypes.number.isRequired,
  cellConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(historyTableRowStyles)(HistoryTableRow);
