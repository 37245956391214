import {
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {
  addEmail,
} from '../../utils/Blacklist/helper';


export function useCreateBlacklistItem() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async(blacklist) => {
      return await addEmail(blacklist.pattern, blacklist.reason);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['blacklist'] }),
  });
}
