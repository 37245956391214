import React from 'react';
import API from '../../api';
import ReactVirtualizedTable from './VirtualizedTable';
import Skeleton from 'react-loading-skeleton';
import { reformatArray } from '../../_helpers';
import { enqueueSnackbar } from 'notistack';


class SystemLogs extends React.Component {
  // prevents memory leaks
  _isMounted = false;

  columnHeaders = [
    { id: 'id', label: 'ID' },
    { id: 'created_at', label: 'Datum' },
    { id: 'system', label: 'System' },
    { id: 'mandate_uuid:', label: 'Mandant' },
    { id: 'type', label: 'Typ' },
    { id: 'error_short', label: 'Fehlermeldung' },
  ];

  constructor(props) {
    super(props);

    this.state = {
      logData: null,
    };
  }

  getLogData() {
    API.getLogData()
      .then((data) => {
        if (this._isMounted) {
          data.forEach(element => {
            element.error_short = reformatArray(element.error_short);
          });
          this.setState({
            logData: data,
          });
        }
      })
      .catch((err) => enqueueSnackbar(err.message));
  }

  componentDidMount() {
    this._isMounted = true;
    this.getLogData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const dataCmp = this.state.logData ? (
      <ReactVirtualizedTable logData={this.state.logData}/>
    ) : (
      <Skeleton count={5} height={30}/>
    );

    return <React.Fragment>{dataCmp}</React.Fragment>;
  }
}


export default SystemLogs;
