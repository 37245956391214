import axios from 'axios';
import { authHeader } from '../_helpers';
import { loadProgressBar } from 'axios-progress-bar';
import { authService } from './authService';

// set API base url
axios.defaults.baseURL = window._env_.REACT_APP_API_HOST + ':' + window._env_.REACT_APP_API_PORT;

const instance = axios.create();

// Appends auth header if present
instance.interceptors.request.use(config => {
  config.headers.Authorization = authHeader();
  return config;
});

// Checks response and logs user out if response has statuscode 401 Unauthorized or 403 Forbidden
// Any status code that lies without the range of 2xx causes the error-function to trigger
instance.interceptors.response.use(function(response) {
  return response.data;
}, function(error) {
  if ([401, 403].indexOf(error.response.status) !== -1) {
    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
    authService.logout();
    window.location.reload(true);
  }
  return Promise.reject(error);
});

// adds progress bar
loadProgressBar(null, instance);

export default instance;

const authInstance = axios.create();
export { authInstance };
