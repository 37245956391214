import { parseISO, format } from 'date-fns';


// const date = parseISO(row['created_at']);
// if (date instanceof Date && isFinite(date)) {
//   row['created_at'] = format(parseISO(row['created_at']), 'dd.MM.yyyy - hh:mm');
// }


export function parseDate(dateString) {
  const date = parseISO(dateString);
  if (date instanceof Date && isFinite(date)) {
    return format(date, 'dd.MM.yyyy - HH:mm');
  }
  return dateString;
}
