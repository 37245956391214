import React from 'react';

import API from '../../api';
import BarChart from './BarChart';
import SystemLogs from './SystemLogs';

import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import Skeleton from 'react-loading-skeleton';

import format from 'date-fns/format';
import { Filter } from '../filter/Filter';

import { enqueueSnackbar } from 'notistack';
import { SystemMandateFilters } from '../filter/SystemMandateFilters';

class Dashboard extends React.Component {
  // prevents memory leaks
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  handleFilterChange(filterValues) {
    const system = SystemMandateFilters[filterValues.systemMandateIndex].system;
    const mandate = SystemMandateFilters[filterValues.systemMandateIndex].mandate;
    const type = filterValues.type;
    const date = filterValues.selectedDate;
    const environment = filterValues.environment;
    this.getData(system, mandate, type, date, environment);
  }

  getData(system, mandate, type, date, environment) {
    API.getMessageStatistics(
      system,
      mandate,
      type,
      format(date, 'yyyy-MM-dd'),
      environment)
      .then((data) => {
        if (this._isMounted) {
          this.setState({
            data: data,
          });
        }
      })
      .catch((err) => enqueueSnackbar(err.message));
  }


  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let dataComponents =
      this.state.data !== null ? (
        <React.Fragment>
          <Grid size={{ xs: 12, md: 9 }}>
            <Paper>
              <BarChart data={this.state.data}/>
            </Paper>
          </Grid>
          <Grid size={{ xs: 12, md: 3 }}>
            <Paper>
              <div style={{ padding: '1em' }}>
                <div style={{ padding: '0.5em' }}>
                  <Typography component="span" variant="h6">Erfolgreich gesendet:</Typography>
                  <Typography variant="body1">
                    {this.state.data.send_successful}
                  </Typography>
                </div>
                <Divider/>
                <div style={{ padding: '0.5em' }}>
                  <Typography component="span" variant="h6">Nicht gesendet:</Typography>
                  <Typography variant="body1">
                    {this.state.data.send_error}
                  </Typography>
                </div>
              </div>
            </Paper>
          </Grid>
        </React.Fragment>
      ) : (
        <Grid size={{ xs: 12 }}>
          <Skeleton count={6} height={30}/>
        </Grid>
      );

    return (
      <React.Fragment>
        <Typography variant="h5" gutterBottom>
          Dashboard
        </Typography>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12 }}>
            <Paper>
              <div style={{ margin: '1em' }}>
                <Filter onChange={this.handleFilterChange}/>
              </div>
            </Paper>
          </Grid>
          {dataComponents}
        </Grid>
        <Divider style={{ margin: '1.5em' }}/>
        <Typography component="span" variant="h6" gutterBottom>
          System logs
        </Typography>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12 }}>
            <SystemLogs/>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Dashboard;
