import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Footer from '../layout/Footer';
import Alert from '@mui/material/Alert';

import { authService } from '../../api';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

export default function LoginPage({ history, history: { location }}) {
  const navigate = useNavigate();
  const classes = useStyles();

  const [email, setEmail] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [faultyCredentials, setFaultyCredentials] = React.useState(false);
  const [failedLogin, setFailedLogin] = React.useState(false);

  const handleLogin = () => {
    setFaultyCredentials(!(email && password));
    if (email && password) {
      authService.login(email, password)
        .then(response => {
          if ([401, 403].indexOf(response.status) !== -1) {
            setFailedLogin(true);
          } else {
            const { from } = location.state || { from: { pathname: '/' } };
            history.push(from);
            navigate('/');
          }
        });
    }
  };

  useEffect(() => {
    if (authService.currentUserValue) {
      history.push('/');
      navigate('/');
    }
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <div className={classes.paper}>
        <Avatar sx={{
          margin: (theme) => theme.spacing(1),
          backgroundColor: (theme) => theme.palette.secondary.main,
        }}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        <form className={classes.form}>
          <TextField
            color="primary"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={faultyCredentials}
            id="email"
            label="E-Mail-Adresse"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            color="primary"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={faultyCredentials}
            name="password"
            label="Passwort"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ margin: (theme) => theme.spacing(3, 0, 2) }}
            onClick={() => handleLogin()}
          >
            Log in
          </Button>
        </form>
        <div style={{ height: 100 }}>
          {faultyCredentials ? (
            <Alert style={{ margin: 10 }} severity="warning">Bitte alle Felder ausfüllen</Alert>) : null
          }
          {failedLogin ? (
            <Alert style={{ margin: 10 }} severity="error">Login fehlgeschlagen</Alert>) : null
          }
        </div>
      </div>
      <Footer/>
    </Container>
  );
}
