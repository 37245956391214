import React from 'react';
import Typography from '@mui/material/Typography';

export default class Footer extends React.Component {
  render() {
    return (
      <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: 50, marginBottom: 50 }}>
        {'Copyright © '}
        <a
          style={{ color: 'inherit' }}
          href="https://www.saubermacher.at"
          target="_blank"
          rel="noopener noreferrer"
        >
          Saubermacher Dienstleistungs AG
        </a>{' '}
        {new Date().getFullYear()}
      </Typography>
    );
  }
}
