import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import {
  AutoSizer,
  Column,
  /***
   DEPRECATED
   Table ist momentan noch nicht Aktualisiert worden von react-virtualized und kann mit react 19 dann Probleme machen!
   ***/
  Table,
} from 'react-virtualized';
import { parseDate } from '../../_helpers';

const styles = (theme) => ({
  flexContainer: {
    display: 'flex', alignItems: 'center', boxSizing: 'border-box',
  }, table: {
    '& .ReactVirtualized__Table__headerRow': {
      flip: false, paddingRight: theme.direction === 'rtl' ? '0px !important' : undefined,
    },
  }, tableRow: {
    cursor: 'pointer',
  }, tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  }, tableCell: {
    flex: 1,
  }, noClick: {
    cursor: 'initial',
  },
});

class MuiVirtualizedTable extends React.PureComponent {
  static defaultProps = {
    headerHeight: 48, rowHeight: 48,
  };

  getRowClassName = ({ index }) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer = ({ cellData, columnIndex }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;
    return (<TableCell
      component="div"
      className={clsx(classes.tableCell, classes.flexContainer, {
        [classes.noClick]: onRowClick == null,
      })}
      variant="body"
      style={{ height: rowHeight }}
      align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
    >
      {cellData}
    </TableCell>);
  };

  headerRenderer = ({ label, columnIndex }) => {
    const { headerHeight, columns, classes } = this.props;

    return (<TableCell
      component="div"
      className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
      variant="head"
      style={{ height: headerHeight }}
      align={columns[columnIndex].numeric || false ? 'right' : 'left'}
    >
      <span>{label}</span>
    </TableCell>);
  };

  render() {
    const {
      classes, columns, rowHeight, headerHeight, ...tableProps
    } = this.props;
    return (<AutoSizer>
      {({ height, width }) => (<Table
        height={height}
        width={width}
        rowHeight={rowHeight}
        gridStyle={{
          direction: 'inherit',
        }}
        headerHeight={headerHeight}
        className={classes.table}
        {...tableProps}
        rowClassName={this.getRowClassName}
      >
        {columns.map(({ dataKey, ...other }, index) => {
          return (<Column
            key={dataKey}
            headerRenderer={(headerProps) => this.headerRenderer({
              ...headerProps, columnIndex: index,
            })}
            className={classes.flexContainer}
            cellRenderer={this.cellRenderer}
            dataKey={dataKey}
            {...other}
          />);
        })}
      </Table>)}
    </AutoSizer>);
  }
}

MuiVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired, columns: PropTypes.arrayOf(PropTypes.shape({
    dataKey: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    numeric: PropTypes.bool,
    width: PropTypes.number.isRequired,
  })).isRequired, headerHeight: PropTypes.number, onRowClick: PropTypes.func, rowHeight: PropTypes.number,
};

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

// ---

export default class ReactVirtualizedTable extends React.Component {

  mandate_map = {
    '132b64f3-9c4c-11e7-af2a-02b2703d30at': 'AT',
    '55b386f2-a8e5-11e7-9da8-0a627e9db9de': 'DE',
  };

  render() {
    const rows = this.props.logData;
    return (<Paper style={{ height: 700, overflowX: 'auto' }}>
      <VirtualizedTable
        rowCount={rows.length}
        rowGetter={({ index }) => {
          const row = rows[index];

          row['created_at'] = parseDate(row['created_at']);

          const mandate_uuid = row['mandate_uuid'];
          row['mandate_uuid'] = this.mandate_map[mandate_uuid] || mandate_uuid;
          return row;
        }}
        columns={[{ dataKey: 'id', label: 'ID', width: 100, numeric: true }, {
          dataKey: 'created_at',
          label: 'Datum',
          width: 250,
        }, { dataKey: 'system', label: 'System', width: 200 }, {
          dataKey: 'mandate_uuid',
          label: 'Mandant',
          width: 200,
        }, { dataKey: 'type', label: 'Typ', width: 200 }, {
          dataKey: 'error_short',
          label: 'Fehlermeldung',
          width: 300,
        }]}
      />
    </Paper>);
  }
}
