import React, { useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import PrevPageIcon from '@mui/material/internal/svg-icons/KeyboardArrowLeft';
import NextPageIcon from '@mui/material/internal/svg-icons/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import ReloadIcon from '@mui/icons-material/Replay';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useHistoryPaginationActionsStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  pageTextField: {
    width: 80,
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(0.5),
    marginLeft: theme.spacing(2.5),
  },
  pageTextFieldInputProps: {
    height: 5,
    fontSize: 18,
    textAlign: 'center',
  },
  typography: {
    fontSize: 18,
    marginRight: theme.spacing(2.5),
  },
}));

// Hook: get value of previous state
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

let timeout = null;

function HistoryPaginationActions({ count, onPageChange, page, rowsPerPage }) {
  const [pageNumber, setPageNumber] = React.useState(1);
  const classes = useHistoryPaginationActionsStyles();
  const lastPageIndex = Math.ceil(count / rowsPerPage) - 1;

  // Update pageNumber when page has changed
  const prevPage = usePrevious(page);
  if (page !== prevPage && pageNumber !== page + 1) {
    setPageNumber(page + 1);
  }

  const handleFirstPageButtonClick = (e) => {
    onPageChange(e, 0);
  };

  const handlePrevButtonClick = (e) => {
    onPageChange(e, page - 1);
  };

  const handleNextButtonClick = (e) => {
    onPageChange(e, page + 1);
  };

  const handleLastPageButtonClick = (e) => {
    onPageChange(e, lastPageIndex);
  };

  const handleReloadButtonClick = (e) => {
    onPageChange(e, page);
  };

  const handlePageTextFieldChange = (event) => {
    clearTimeout(timeout);
    const newPageNumber = event.target.value;
    const numberRegex = /^[0-9\b]+$/;
    if (newPageNumber === '' || numberRegex.test(newPageNumber)) {
      setPageNumber(newPageNumber);
      if (parseInt(newPageNumber) !== page + 1 && newPageNumber >= 1 && newPageNumber <= lastPageIndex + 1) {
        timeout = setTimeout(() => {
          onPageChange(event, newPageNumber - 1);
        }, 250);
      }
    }
  };

  // Reset pageNumber if the page does not exist
  const handlePageTextFieldBlur = (event) => {
    const newPageNumber = event.target.value;
    if (newPageNumber < 1 || newPageNumber > lastPageIndex + 1) {
      setPageNumber(page + 1);
    }
  };

  return (
    <div className={classes.root}>
      <TextField variant="outlined" size="small" value={pageNumber}
                 className={classes.pageTextField}
                 InputProps={{ classes: { input: classes.pageTextFieldInputProps } }}
                 onChange={handlePageTextFieldChange}
                 onBlur={handlePageTextFieldBlur}/>
      <Typography className={classes.typography} component="span">
        / {lastPageIndex + 1}
      </Typography>

      <IconButton onClick={handleReloadButtonClick}>
        <ReloadIcon/>
      </IconButton>

      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
        <FirstPageIcon/>
      </IconButton>

      <IconButton onClick={handlePrevButtonClick} disabled={page === 0}>
        <PrevPageIcon/>
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= lastPageIndex}>
        <NextPageIcon/>
      </IconButton>

      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= lastPageIndex}>
        <LastPageIcon/>
      </IconButton>
    </div>
  );
}

HistoryPaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default HistoryPaginationActions;
