import { enqueueSnackbar } from 'notistack';
import API from '../../api';

export const getBlacklist = () => {
  return API.getMailBlacklist().catch((err) => enqueueSnackbar(err.message));
};

export const addEmail = (email, reason) => {
  return API.postMailBlacklist(email, reason).then(() => getBlacklist());
};

export const removeEmail = (uuid) => {
  return API.deleteMailBlacklistUuid(uuid).then(() => getBlacklist());
};
