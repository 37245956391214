import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';

import { authService } from '../../api';
import { useNavigate } from 'react-router-dom';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    }
}))

export default function Header({ history, navBarOpen, handleNavBarOpen }) {
  const classes = useStyles();
  const navigate = useNavigate();


  const handleLogout = () => {
    authService.logout();
    history.push('/login');
    navigate('/login');
  };

  return (
    <AppBar
      color="primary"
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: navBarOpen,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleNavBarOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: navBarOpen,
          })}
        >
          <MenuIcon/>
        </IconButton>


        <Grid
          width={'100%'}
          justifyContent={'space-between'}
          container
          alignItems={'center'}
        >
          <Grid>
            <Typography component="span" variant="h6" noWrap>
              Wastebox Messaging
            </Typography>
          </Grid>

          <Grid>
            <Button style={{ margin: 5 }} variant="contained" color="secondary"
                    onClick={handleLogout}>Logout</Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>);
}
