import { useQuery } from '@tanstack/react-query';
import { getBlacklist } from '../../utils/Blacklist/helper';

export function useGetBlacklist() {
  return useQuery({
    queryKey: ['blacklist'],
    queryFn: async() => {
      return getBlacklist();
    },
    refetchOnWindowFocus: false,
  });
}
