export function reformatArray(arrayString) {
  try {
    const array = JSON.parse(arrayString);
    return array.join(', ');
  } catch (error) {
    return arrayString;
  }
}

// converts attachments to a html hyperlink
export function reformatAttachmentArray(arrayString) {
  try {
    const format = attachment => `<a href='${attachment.href}' target='_blank'>${attachment.filename}</a>`;
    const array = JSON.parse(arrayString);

    if (Array.isArray(array)) {
      return array.map(attachment => format(attachment)).join(', ');
    }
    return format(array);
  } catch (error) {
    return arrayString;
  }
}
