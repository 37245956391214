const systemMandateFilters = () => {
  let envSystemMandateFilters = [];
  try {
    if (window._env_.SYSTEM_MANDATE_FILTERS) {
      envSystemMandateFilters = JSON.parse(window._env_.SYSTEM_MANDATE_FILTERS || '[]');
    }
  } catch (e) {
    envSystemMandateFilters = [];
  }
  return [
    { text: 'Alle', system: '', mandate: '' },
    { text: 'Wastebox AT', system: 'wastebox', mandate: '132b64f3-9c4c-11e7-af2a-02b2703d30at' },
    { text: 'Wastebox DE', system: 'wastebox', mandate: '55b386f2-a8e5-11e7-9da8-0a627e9db9de' },
    { text: 'Kundenportal', system: 'kundenportal-production', mandate: 'kundenportal' },
    { text: 'Salesportal', system: 'salesportal', mandate: '132b64f3-9c4c-11e7-af2a-02b2703d30at' },
  ].concat(envSystemMandateFilters);
};

export const SystemMandateFilters = systemMandateFilters();
