import {
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import { removeEmail } from '../../utils/Blacklist/helper';

export function useDeleteBlacklistItem() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async(blacklistId) => {
      return await removeEmail(blacklistId);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['blacklist'] }),
  });
}
