import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';

import Header from './Header';
import NavigationDrawer from './NavigationDrawer';
import Footer from './Footer';
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import Dashboard from '../dashboard';
import History from '../history';
import { Blacklist } from '../blacklist/Blacklist.Component';
import { SnackbarProvider } from 'notistack';
import { PrivateRoute } from '../PrivateRoute';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    overflowY: 'auto',
  },
}));

export default function MainComponent({ ...props }) {
  const classes = useStyles();

  const [navBarOpen, setNavBarOpen] = React.useState(false);

  const handleNavBarOpen = () => {
    setNavBarOpen(true);
  };

  const handleNavBarClose = () => {
    setNavBarOpen(false);
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} variant={'error'}>
        <CssBaseline/>
        <Header className={classes.header} navBarOpen={navBarOpen} handleNavBarOpen={handleNavBarOpen} {...props} />
        <NavigationDrawer navBarOpen={navBarOpen} handleNavBarClose={handleNavBarClose}/>
        <main className={classes.content}>
          <div className={classes.toolbar}/>
          <Container maxWidth="xl">
            <Routes>
              <Route path={'/'} element={<PrivateRoute component={Dashboard}/>}/>
              <Route path={'/dashboard'} element={<PrivateRoute component={Dashboard}/>}/>
              <Route path={'/history'} element={<PrivateRoute component={History}/>}/>
              <Route path={'/blacklist'} element={<PrivateRoute component={Blacklist}/>}/>
              <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
            <Footer/>
          </Container>
        </main>
      </SnackbarProvider>
    </div>);
}
