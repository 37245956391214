import * as React from 'react';
import {
  BarChart as BarC,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

export default class BarChart extends React.PureComponent {
  render() {
    return (
      <div>
        <ResponsiveContainer width="100%" height={300}>
          <BarC
            data={this.props.data.count}
            barCategoryGap="20%"
            margin={{ top: 30, right: 30, left: 30, bottom: 5 }}
          >
            <CartesianGrid/>
            <XAxis dataKey="displayName"/>
            <YAxis/>
            <Tooltip/>
            <Bar dataKey="count" fill="#3949AB"/>
          </BarC>
        </ResponsiveContainer>
      </div>
    );
  }
}
