export const BLACKLIST = {
  MAIL_VALIDATION_ERROR: 'Bitte eine E-Mail-Adresse eingeben! Diese wird automatisch zu einem RegEx-Pattern konvertiert.',
  DELETE_CONFIRMATION: 'Diesen Eintrag löschen?',
};

export const BLACKLIST_TABLE = {
  TABLE_ACTIONS: 'Aktionen',
  TABLE_ADD_TOOLTIP: 'Hinzufügen',
  TABLE_ROW_DELETE: 'Löschen',
  TABLE_SEARCH: 'Suchen',
  TABLE_SAVE: 'Speichern',
  TABLE_CANCEL: 'Abbrechen',
  TABLE_CLEAR_SEARCH: '',
}
