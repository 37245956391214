import instance from './instance';

// needed because axios sends params with '', but doesnt send null-params - and '' is required to have controlled components
const checkParameter = (parameter) => {
  return parameter === '' ? null : parameter;
};

export function getMessages(
  system,
  mandate,
  type,
  fromDate,
  searchTerm,
  environment,
  perPage,
  currentPage,
) {
  return instance.get('/messages', {
    params: {
      system: checkParameter(system),
      mandate: checkParameter(mandate),
      type: checkParameter(type),
      from_date: fromDate,
      search_term: checkParameter(searchTerm),
      environment: checkParameter(environment),
      rows_per_page: perPage,
      current_page: currentPage,
    },
  });
}

export function getMessageStatistics(system, mandate, type, fromDate, environment) {
  return instance.get('/messageStatistics', {
    params: {
      system: checkParameter(system),
      mandate: checkParameter(mandate),
      type: checkParameter(type),
      from_date: fromDate,
      environment: checkParameter(environment),
    },
  });
}

export function getLogData() {
  return instance.get('/logdata');
}

export function getMailBlacklist() {
  return instance.get('/mailblacklist');
}

export function postMailBlacklist(email, reason) {
  return instance.post('/mailblacklist', {
    email: email,
    reason: reason,
  });
}

export function deleteMailBlacklistUuid(uuid) {
  return instance.delete('/mailblacklist/uuid/' + uuid);
}

export function deleteMailBlacklistPattern(pattern) {
  return instance.delete('/mailblacklist/' + pattern);
}
