import API from '../../api';
import React from 'react';
import { MAX_PAST_DATE } from '../../constants/Variables/filter';
import HistoryTable from './HistoryTable';
import { Filter } from '../filter/Filter';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';
import { add, format } from 'date-fns';
import {
  parseDate,
  reformatArray,
  reformatAttachmentArray,
} from '../../_helpers';
import { enqueueSnackbar } from 'notistack';
import { SystemMandateFilters } from '../filter/SystemMandateFilters';

class History extends React.Component {
  // prevents memory leaks
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      filter: {
        systemMandateIndex: 1,
        type: 'mail',
        environment: 'productive',
        date: add(new Date(), MAX_PAST_DATE),
        searchTerm: null,
      }, pagination: {
        total: null, lastPage: null, perPage: 10, currentPage: 0, from: null, to: null,
      },
    };

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleRowsPerPageChange = this.handleRowsPerPageChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  handlePageChange(page) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination, currentPage: page,
      },
    }), () => {
      this.getMessages();
    });
  }

  handleRowsPerPageChange(perPage) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination, perPage: perPage, currentPage: 0,
      },
    }), () => {
      this.getMessages();
    });
  }

  handleFilterChange(filterValues) {
    this.setState(prevState => ({
      filter: {
        systemMandateIndex: filterValues.systemMandateIndex,
        type: filterValues.type,
        date: filterValues.selectedDate,
        searchTerm: filterValues.searchTerm,
        environment: filterValues.environment,
      }, pagination: {
        ...prevState.pagination, currentPage: 0,
      },
    }), () => this.getMessages());
  }

  getMessages() {
    const filter = this.state.filter;
    const pagination = this.state.pagination;

    API.getMessages(SystemMandateFilters[filter.systemMandateIndex].system, SystemMandateFilters[filter.systemMandateIndex].mandate, filter.type, format(filter.date, 'yyyy-MM-dd'), filter.searchTerm, filter.environment, pagination.perPage, pagination.currentPage)
      .then(messages => {
        if (this._isMounted) {
          messages.data.forEach(message => {
            message.send_date = parseDate(message.send_date);
            message.error = reformatArray(message.error);
            message.attachments = reformatAttachmentArray(message.attachments);
            message.receiver = reformatArray(message.receiver);
          });
          this.setState({
            data: messages.data, pagination: messages.pagination,
          });
        }
      })
      .catch((err) => enqueueSnackbar(err.message));
  }

  componentDidMount() {
    if (!this._isMounted) {
      this._isMounted = true;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (<React.Fragment>
      <Typography variant={'h5'} gutterBottom>
        History
      </Typography>
      <Grid container spacing={1}>
        <Grid size={{ xs: 12 }}>
          <Paper>
            <div style={{ margin: '1em' }}>
              <Filter search onChange={this.handleFilterChange}/>
            </div>
          </Paper>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <HistoryTable
            data={this.state.data}
            pagination={this.state.pagination}
            onChangePage={(page) => this.handlePageChange(page)}
            onChangeRowsPerPage={(perPage) => this.handleRowsPerPageChange(perPage)}
          />
        </Grid>
      </Grid>
    </React.Fragment>);
  }
}

export default History;
