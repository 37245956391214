import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

import LoginPage from './components/login/LoginPage';

import { browserHistory } from './_helpers';
import MainComponent from './components/layout/MainComponent';

class App extends React.Component {

  render() {
    return (
      <BrowserRouter history={browserHistory} basename="/">
        <Routes>
          <Route path={'/*'} element={<MainComponent history={browserHistory}/>}/>
          <Route path={'/login'} element={<LoginPage history={browserHistory}/>}/>
        </Routes>
      </BrowserRouter>
    );
  }

}

export default App;
