import { authInstance } from './instance';

export const authService = {
  login,
  logout,
  get currentUserValue() {
    return JSON.parse(localStorage.getItem('currentUser'));
  },
};

function login(email, password) {
  return authInstance.post('/login', {
    email: email,
    password: password,
  })
    .then(response => {
      const userData = response.data;
      localStorage.setItem('currentUser', JSON.stringify(userData));
      return response;
    })
    .catch(error => {
      if (error.response) {
        return error.response;
      }
    });
}

function logout() {
  //remove user from local storage
  localStorage.removeItem('currentUser');
}
