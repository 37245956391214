import { authService } from './authService';
import {
  getMessages,
  getMessageStatistics,
  getLogData,
  getMailBlacklist,
  postMailBlacklist,
  deleteMailBlacklistUuid,
  deleteMailBlacklistPattern,
} from './api';

export { authService };
export default {
  getMessages,
  getMessageStatistics,
  getLogData,
  getMailBlacklist,
  postMailBlacklist,
  deleteMailBlacklistUuid,
  deleteMailBlacklistPattern,
};
