import React, { useMemo, useState } from 'react';
import {
  MaterialReactTable, MRT_GlobalFilterTextField, useMaterialReactTable,
} from 'material-react-table';
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Check, Clear } from '@mui/icons-material';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { AddBox, DeleteOutline } from '@mui/icons-material';
import { BLACKLIST, BLACKLIST_TABLE } from '../../constants/Translations/blacklist';
import { useCreateBlacklistItem } from '../../hooks/Blacklist/useCreateBlacklistItem';
import { useDeleteBlacklistItem } from '../../hooks/Blacklist/useDeleteBlacklistItem';
import { useGetBlacklist } from '../../hooks/Blacklist/useGetBlacklist';
import { enqueueSnackbar } from 'notistack';
import Paper from '@mui/material/Paper';
import { validateEmail } from '../../utils/validations/email';

function validateBlacklistItem(blacklist) {
  return {
    pattern: !validateEmail(blacklist.pattern) ? BLACKLIST.MAIL_VALIDATION_ERROR : '',
  };
}
const BlacklistComponent = () => {
  const [creatingRow, setCreatingRow] = useState(false);
  const columns = useMemo(() => [{
    accessorKey: 'pattern', header: 'Pattern', muiTableBodyCellProps: {
      sx: {
        fontWeight: 'bold', fontFamily: 'Courier New',
      },
    },
  }, {
    accessorKey: 'reason', header: 'Grund',
  }], []);

  //call CREATE hook
  const {
    mutateAsync: createBlacklistItem,
    isPending: isCreatingBlacklistItem
  } = useCreateBlacklistItem();

  //call READ hook
  const {
    data: fetchedBlacklist = [],
    isError: isLoadingBlacklistError,
    isFetching: isFetchingBlacklist,
    isLoading: isLoadingBlacklist,
  } = useGetBlacklist();

  //call DELETE hook
  const {
    mutateAsync: deleteBlacklistItem,
    isPending: isDeletingBlacklistItem
  } = useDeleteBlacklistItem();

  //CREATE action
  const handleCreateBlacklistItem = async({
    values, table,
  }) => {
    const newValidationErrors = validateBlacklistItem(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      enqueueSnackbar(BLACKLIST.MAIL_VALIDATION_ERROR);
      return;
    }
    await createBlacklistItem(values);
    setCreatingRow(false);
    table.setCreatingRow(null); //exit creating mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm(BLACKLIST.DELETE_CONFIRMATION)) {
      deleteBlacklistItem(row.original.uuid);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: fetchedBlacklist,
    createDisplayMode: 'row', // ('modal', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row) => row.uuid,
    initialState: { showGlobalFilter: true },
    positionGlobalFilter: 'none',
    muiToolbarAlertBannerProps: isLoadingBlacklistError ? {
      color: 'error', children: 'Error loading data',
    } : undefined,
    onCreatingRowSave: handleCreateBlacklistItem,
    onCreatingRowCancel: ()=>{
      setCreatingRow(false);
    },
    positionActionsColumn: 'last',
    enableFilters: true,
    enablePagination: false,
    columnResizeMode: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableHiding: false,
    positionCreatingRow: 'top',
    localization: {
      actions: BLACKLIST_TABLE.TABLE_ACTIONS,
      addTooltip: BLACKLIST_TABLE.TABLE_ADD_TOOLTIP,
      rowActions: BLACKLIST_TABLE.TABLE_ROW_DELETE,
      search: BLACKLIST_TABLE.TABLE_SEARCH,
      save: BLACKLIST_TABLE.TABLE_SAVE,
      cancel: BLACKLIST_TABLE.TABLE_CANCEL,
      clearSearch: BLACKLIST_TABLE.TABLE_CLEAR_SEARCH,
    },
    icons: {
      SaveIcon: (props) => <Check sx={{ color: 'grey' }}/>, CancelIcon: (props) => <Clear sx={{ color: 'grey' }}/>,
    },
    renderRowActions: ({ row, table }) => (<Box sx={{ display: 'flex', gap: '1rem' }}>
      <Tooltip title={BLACKLIST_TABLE.TABLE_ROW_DELETE}>
        <IconButton onClick={() => openDeleteConfirmModal(row)}>
          <DeleteOutline/>
        </IconButton>
      </Tooltip>
    </Box>),
    renderTopToolbarCustomActions: ({ table }) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <MRT_GlobalFilterTextField
          variant="standard"
          table={table}/>
        <Tooltip title={BLACKLIST_TABLE.TABLE_ADD_TOOLTIP}>
          <IconButton color="Inherit" onClick={() => {
            setCreatingRow(!creatingRow);
            table.setCreatingRow(!creatingRow ? true : null);
          }}>
            <AddBox/>
          </IconButton>
        </Tooltip>
      </div>

    ),
    state: {
      isLoading: isLoadingBlacklist,
      isSaving: isCreatingBlacklistItem || isDeletingBlacklistItem,
      showAlertBanner: isLoadingBlacklistError,
      showProgressBars: isFetchingBlacklist,
    },
  });

  return <MaterialReactTable table={table}/>;
};

const queryClient = new QueryClient();
export const Blacklist = () => (<React.Fragment>
  <Typography variant="h5" gutterBottom>
    Blacklist
  </Typography>
  <Paper sx={{ width: '100%' }}>
    <QueryClientProvider client={queryClient}>
      <BlacklistComponent/>
    </QueryClientProvider>
  </Paper>
</React.Fragment>);
