import { createTheme } from '@mui/material/styles';

// Definiere dein Theme
const theme = createTheme({
  palette: {
    alternateTextColor: '#3f51b5',
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#fafafa',
    },
  },
  spacing: 8,
  components: {
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: '#3f51b5',
          color: '#fff',
          borderRadius: '4px 4px 0 0',
        },
      },
    },
  },
});

export default theme;
