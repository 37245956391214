import { authService } from '../api/authService';

export function authHeader() {
  // return authorization header with jwt token
  const currentUser = authService.currentUserValue;
  if (currentUser && currentUser.token) {
    return `Bearer ${currentUser.token}`;
  } else {
    return {};
  }
}
