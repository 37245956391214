import React from 'react';
import { Navigate } from 'react-router-dom';

import { authService } from '../api';

export function PrivateRoute({ component: Component, ...rest }) {
  const currentUser = authService.currentUserValue;
  if (!currentUser) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" state={{ from: rest.location }}/>;
  }

  // authorised so return component with header and navbar
  return (<Component {...rest} />);
}
