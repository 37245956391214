import React from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import HistoryTableRow from './HistoryTableRow';
import HistoryPaginationActions from './HistoryPaginationActions';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

const useHistoryTableStyles = makeStyles({
  table: {
    minWidth: 500,
    tableLayout: 'fixed',
  },
  container: {
    height: 675,
  },
  headerRow: {
    height: 60,
  },
  cell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    align: 'left',
  },
});

function HistoryTable({ data, onChangePage, onChangeRowsPerPage, pagination: { total, perPage, currentPage } }) {
  const classes = useHistoryTableStyles();

  const cellConfig = [
    { desc: 'System', width: '10%' },
    { desc: 'Zeitpunkt', width: '15%' },
    { desc: 'Betreff', width: '15%' },
    { desc: 'Inhalt', width: '15%' },
    { desc: 'Anhänge', width: '10%' },
    { desc: 'Empfänger', width: '10%' },
    { desc: 'Environment', width: '15%' },
    { desc: 'Status', width: '10%' },
    { desc: '', width: '70px' },
  ];

  const handleRowsPerPageChange = event => {
    onChangeRowsPerPage(event.target.value);
  };

  return data ? (
    <Paper>
      <TableContainer className={classes.container}>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow className={classes.headerRow}>
              {cellConfig.map((item, index) => (
                <TableCell key={index} className={classes.cell} style={{ width: item.width }}>
                  <Typography>{item.desc}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <HistoryTableRow
                key={index}
                item={item}
                dataID={index}
                cellConfig={cellConfig}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Table className={classes.table}>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={total}
              rowsPerPage={perPage}
              page={currentPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              ActionsComponent={HistoryPaginationActions}
              onPageChange={(event, page) => onChangePage(page)}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  ) : (
    <Skeleton count={10} height={60}/>
  );
}

HistoryTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  pagination: PropTypes.object.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
};

export default HistoryTable;
