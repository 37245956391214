import React, { useState } from 'react';
import {
  Grid2 as Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from '@mui/material';
import deLocale from 'date-fns/locale/de';
import { DatePicker } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import add from 'date-fns/add';
import { useOnMount } from '@mui/x-data-grid';
import { MAX_PAST_DATE } from '../../constants/Variables/filter';
import { SystemMandateFilters } from './SystemMandateFilters';
import { deDE } from '@mui/x-date-pickers/locales';


export const Filter = ({
  search = false,
  systemMandateIndex = true,
  environment = true,
  date = true,
  type = true,
  onChange = () => {
  },
}) => {
  let _isMounted = false;
  const handleChange = onChange;
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    systemMandateIndex: 1,
    type: 'mail',
    selectedDate: add(new Date(), MAX_PAST_DATE),
    environment: 'productive',
    searchTerm: '',
  });
  useOnMount(() => {
    if (!_isMounted) {
      _isMounted = true;
      handleChange(state);
    }
  });

  const handleChangeSystemMandateIndex = (event) => {
    setState((oldState) => ({ ...oldState, systemMandateIndex: event.target.value }));
    handleChange({ ...state, systemMandateIndex: event.target.value });
  };

  const handleChangeType = (event) => {
    setState((oldState) => ({ ...oldState, type: event.target.value }));
    handleChange({ ...state, type: event.target.value });
  };

  const handleChangeEnvironment = (event) => {
    setState((oldState) => ({ ...oldState, environment: event.target.value }));
    handleChange({ ...state, environment: event.target.value });
  };

  const handleChangeDate = (date) => {
    setState((oldState) => ({ ...oldState, selectedDate: date }));
    handleChange({ ...state, selectedDate: date });
  };

  let timeout = null;
  const handleChangeSearch = (event) => {
    const value = event.target.value;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState((oldState) => ({ ...oldState, searchTerm: value }));
      handleChange({ ...state, searchTerm: value });
    }, 250);
  };


  const systemMandateIndexComponent = (
    <FormControl variant="standard" fullWidth>
      <InputLabel>System</InputLabel>
      <Select
        value={state.systemMandateIndex}
        onChange={handleChangeSystemMandateIndex}
      >
        {
          SystemMandateFilters.map((filter, index) => (
            <MenuItem key={index} value={index}>{filter.text}</MenuItem>
          ))
        }
      </Select>
    </FormControl>);

  const typeComponent = (
    <FormControl variant="standard" fullWidth>
      <InputLabel>Typ</InputLabel>
      <Select value={state.type} onChange={handleChangeType}>
        <MenuItem value="">
          <em>Alle</em>
        </MenuItem>
        <MenuItem value="mail">Mail</MenuItem>
        <MenuItem value="sms">SMS</MenuItem>
        <MenuItem value="push">Push</MenuItem>
      </Select>
    </FormControl>);

  const environmentComponent = (
    <FormControl variant="standard" fullWidth>
      <InputLabel id={'environment-label'}>Environment</InputLabel>
      <Select labelId="demo-simple-select-label" label="Environment" value={state.environment}
              onChange={handleChangeEnvironment}>
        <MenuItem value="">
          <em>Alle</em>
        </MenuItem>
        <MenuItem value="productive">Productive</MenuItem>
        <MenuItem value="non-productive">Non-productive</MenuItem>
      </Select>
    </FormControl>);
  const dateComponent = (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}
                          localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}>
      <DatePicker
        slotProps={{
          textField: {
            variant: 'standard',
            onClick: () => setOpen(true),
            readOnly: true,
          },
          toolbar: {
            toolbarFormat: 'EE, dd MMM',
            sx: {
              '& span.MuiTypography-root': {
                display: 'none',
              },
            },
          },
          actionBar: {},
        }}
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        minDate={add(new Date(), MAX_PAST_DATE)}
        disableOpenPicker
        format="dd.MM.yyyy"
        disableFuture
        label="Statistik beginnen mit dem"
        value={state.selectedDate}
        onChange={handleChangeDate}
      />
    </LocalizationProvider>);

  const doChange = event => {
    if (event.keyCode === 13) {
      const value = event.target.value;
      clearTimeout(timeout);
      setState((oldState) => ({ ...oldState, searchTerm: value }));
      handleChange({ ...state, searchTerm: value });
    }
  };
  const searchComponent = (
    <FormControl fullWidth>
      <InputLabel>Suche</InputLabel>
      <Input
        onChange={handleChangeSearch}
        onKeyDown={doChange}
      />
    </FormControl>
  );


  return (
    <Grid container spacing={3} paddingY={3}>
      {systemMandateIndex ? (
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          {systemMandateIndexComponent}
        </Grid>) : null}
      {type ? (
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          {typeComponent}
        </Grid>) : null}
      {environment ? (
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          {environmentComponent}
        </Grid>) : null}
      {date ? (
        <Grid size={{ xs: 12, md: 12, lg: 3 }}>
          {dateComponent}
        </Grid>) : null}
      {search ? (
        <Grid size={{ xs: 12, md: 12, lg: 6 }}>
          {searchComponent}
        </Grid>) : null}
    </Grid>
  );

};

